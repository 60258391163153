import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";
import "../css/AccountComponent.css";

const AccountComponent = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [errorAccount, setErrorAccount] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        setLoadingAccount(true);

        const response = await fetch(APIEndtPoint + "v1/profiles/get_profile", {
          headers: {
            Authorization: token,
          },
        });
        if (!response.ok) {
          throw new Error("Profile not found. Please complete your profile.");
        }

        const data = await response.json();
        setAccountDetails(data || {});
      } catch (error) {
        setErrorAccount(error.message);
      } finally {
        setLoadingAccount(false);
      }
    };

    fetchAccountDetails();
  }, []);

  const handleAccountChange = (e) => {
    const { name, value } = e.target;
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      data: {
        ...prevDetails.data,
        attributes: {
          ...prevDetails.data.attributes,
          [name]: value,
        },
      },
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleAccountSubmit = async (e) => {
    e.preventDefault();
    setUpdateError(null);

    try {
      const formData = new FormData();
      formData.append(
        "profile[first_name]",
        accountDetails.data.attributes.first_name
      );
      formData.append(
        "profile[last_name]",
        accountDetails.data.attributes.last_name
      );
      formData.append(
        "profile[actual_address]",
        accountDetails.data.attributes.actual_address
      );
      formData.append(
        "profile[locality]",
        accountDetails.data.attributes.locality
      );
      if (selectedFile) {
        formData.append("profile[document]", selectedFile);
      }

      const response = await fetch(
        APIEndtPoint + "v1/profiles/update_profile",
        {
          method: "PUT",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData || "Failed to update account details.";
        throw new Error(errorMessage);
      }
      alert("Account details updated successfully!");
      setIsEditing(false);
    } catch (error) {
      setUpdateError(error.message);
    }
  };

  if (loadingAccount) {
    return <p>Loading account details...</p>;
  }

  if (errorAccount) {
    return <p>{errorAccount}</p>;
  }

  return (
    <div className="tab-content">
      <h3>Personal Information</h3>
      {isEditing ? (
        <form className="row" onSubmit={handleAccountSubmit}>
          <div className="form-group col-lg-6">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="Enter Your First Name"
              value={
                accountDetails && accountDetails.data.attributes.first_name
              }
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group col-lg-6">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Enter Your Last Name"
              value={accountDetails && accountDetails.data.attributes.last_name}
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group col-lg-6">
            <label htmlFor="actual_address">Address</label>
            <input
              type="text"
              id="actual_address"
              name="actual_address"
              placeholder="Enter your personal address"
              value={
                accountDetails && accountDetails.data.attributes.actual_address
              }
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group col-lg-6">
            <label htmlFor="locality">Locality</label>
            <input
              type="text"
              id="locality"
              name="locality"
              placeholder="Enter locality"
              value={accountDetails && accountDetails.data.attributes.locality}
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group col-lg-12">
            <label htmlFor="document_url">Select Document</label>
            <input
              type="file"
              id="document_url"
              name="document_url"
              onChange={handleFileChange}
            />
          </div>
          <div className="form-group col-lg-12">
            <button type="submit">Save Changes</button>
          </div>

          {updateError && (
            <div className="error-message">
              <p style={{ color: "red" }}>{updateError}</p>
            </div>
          )}
        </form>
      ) : (
        <div className="account-details">
          <p>
            <strong>Full Name:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.full_name}
          </p>
          <p>
            <strong>Residence Address:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.actual_address}
          </p>
          <p>
            <strong>Locality:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.locality}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.email}
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.phone_number}
          </p>
          {accountDetails.data.attributes.document_url && (
            <p>
              <strong>Document:</strong>{" "}
              <a
                href={accountDetails.data.attributes.document_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Document
              </a>
            </p>
          )}
          <button onClick={() => setIsEditing(true)}>Edit</button>
        </div>
      )}
    </div>
  );
};

export default AccountComponent;
