import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";

function BookingsHistory() {
  const [bookings, setBookings] = useState([]);
  const [activeTab, setActiveTab] = useState("Completed");

  useEffect(() => {
    async function fetchBookingsHistory() {
      try {
        const response = await fetch(
          `${APIEndtPoint}/bookings/vendor_past_bookings`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("userToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch bookings");
        }
        const data = await response.json();
        setBookings(data);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    }

    fetchBookingsHistory();
  }, []);

  const filteredBookings = bookings.filter((booking) =>
    activeTab === "Completed"
      ? booking.booking_status === "Completed"
      : activeTab === "Accepted"
      ? booking.booking_status === "Accepted"
      : booking.booking_status === "Rejected"
  );

  return (
    <div>
      <style>
        {`
          .bookings {
            text-align: left;
          }
          .tabs-booking {
            display: flex;
            gap: 10px;
            margin-bottom: 20px;
          }
          .tab-button {
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            border-radius: 4px;
          }
          .tab-button.active {
            background-color:#9fcced;
            color: black;
          }
          .bookings-list {
            display: flex;
            flex-direction: row;
            gap: 20px;
          }
          .booking-card {
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 16px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          .booking-details {
            margin-bottom: 12px;
          }
          .bookingid {
            font-size: 0.9rem;
          }
          .booking-actions {
            display: flex;
            gap: 10px;
          }
          .accept-button,
          .reject-button {
            padding: 8px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 32px;
            align-items: center;
            display: flex;
            justify-content: center;
          }
          .accept-button {
            background-color: #4caf50;
            color: white;
          }
          .reject-button {
            background-color: #f44336;
            color: white;
          }
          .accept-button:hover {
            background-color: #45a049;
          }
          .reject-button:hover {
            background-color: #e53935;
          }
          .accept-button.disabled,
          .reject-button.disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        `}
      </style>
      <h2 className="bookings">Bookings History</h2>

      <div className="tabs-booking">
        <button
          className={`tab-button col-lg-6 border-bottom ${
            activeTab === "Completed" ? "active fw-bold" : ""
          }`}
          onClick={() => setActiveTab("Completed")}
        >
          Completed
        </button>
        <button
          className={`tab-button col-lg-6 border-bottom ${
            activeTab === "Rejected" ? "active fw-bold" : ""
          }`}
          onClick={() => setActiveTab("Rejected")}
        >
          Rejected
        </button>
      </div>

      {filteredBookings.length > 0 ? (
        <div className="bookings-list">
          {filteredBookings.map((booking) => (
            <div className="booking-card col-lg-3" key={booking.id}>
              <div className="booking-details">
                <p>
                  <strong>Customer Name:</strong>{" "}
                  {`${booking.first_name} ${booking.last_name}`}
                </p>
                <p>
                  <strong> Service:</strong> {booking.products.short_title}
                </p>
                <p>
                  <strong> Phone number:</strong> {booking.phone_number}
                </p>
                <p>
                  <strong>Date:</strong> {booking.booking_date}
                </p>
                <p>
                  <strong>Status:</strong> {booking.booking_status}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No bookings available.</p>
      )}
    </div>
  );
}

export default BookingsHistory;
