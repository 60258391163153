import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../css/Registration.css";
import { APIEndtPoint } from "./Apis";

const Registration = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    email: "",
    confirmPassword: "",
  });

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const navigate = useNavigate();

  const signUpUrl = `${APIEndtPoint}v1/signup`;
  const loginUrl = `${APIEndtPoint}v1/login`;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = isSignUp ? signUpUrl : loginUrl;
    const payload = isSignUp
      ? {
          phone_number: formData.phone,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
          role: "vendor",
        }
      : {
          email: formData.email,
          password: formData.password,
        };

    if (isSignUp && formData.password !== formData.confirmPassword) {
      setErrorMessages(["Passwords do not match!"]);
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();

        if (errorData.error && Array.isArray(errorData.error)) {
          setErrorMessages(errorData.error);
        } else if (errorData.error) {
          setErrorMessages([errorData.error]);
        } else {
          setErrorMessages(["An unexpected error occurred. Please try again."]);
        }
        return;
      }

      const data = await response.json();
      setErrorMessages([]);
      localStorage.setItem("userToken", data.token);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessages([error.message]);
    }
  };

  const inputGroupStyle = {
    position: "relative",
  };

  const passwordInputStyle = {
    width: "100%",
    paddingRight: "40px",
  };

  const iconContainerStyle = {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    color: "#888",
  };

  const iconHoverStyle = {
    color: "#333",
  };

  return (
    <div className="registration-container">
      <div className="registration-card">
        <h2>{isSignUp ? "Vendor Sign Up" : "Sign In"}</h2>
        <form onSubmit={handleSubmit}>
          {isSignUp && (
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) handleChange(e);
                }}
                required={isSignUp}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div style={inputGroupStyle}>
              <input
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                name="password"
                style={passwordInputStyle}
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
                pattern={`${
                  !isSignUp
                    ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[!@#$%^&*()_+={}[]:;,.<>?]).{8,}$"
                    : ".*"
                }`}
                title="Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long."
              />
              <span
                style={iconContainerStyle}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = iconHoverStyle.color)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = iconContainerStyle.color)
                }
                onClick={() => setPasswordVisible((prev) => !prev)}
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEyeSlash : faEye}
                />
              </span>
            </div>
          </div>
          {isSignUp && (
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div style={inputGroupStyle}>
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  style={passwordInputStyle}
                  placeholder="Confirm your password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <span
                  style={iconContainerStyle}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.color = iconHoverStyle.color)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.color = iconContainerStyle.color)
                  }
                  onClick={() => setConfirmPasswordVisible((prev) => !prev)}
                >
                  <FontAwesomeIcon
                    icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                  />
                </span>
              </div>
            </div>
          )}
          <button type="submit" className="submit-btn">
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>
        </form>

        {errorMessages.length > 0 && (
          <div className="error-messages">
            {errorMessages.map((error, index) => (
              <p key={index} className="error-message">
                {error}
              </p>
            ))}
          </div>
        )}

        <p className="toggle-text">
          {isSignUp ? "Already have an account?" : "Don't have an account?"}
          <span onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? " Sign In" : " Sign Up"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Registration;
