import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCog,
  faUser,
  faBox,
  faSignOutAlt,
  faCalendar,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import "../css/Dashboard.css";
// import dashboard from "../img/dashboard.jpeg";

const Dashboard = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const navigate = useNavigate();
  const [active, setActive] = useState("services");

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      navigate("/registration");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/registration");
  };

  const handleNavigation = (path) => {
    setActive(path);
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div
      className="dashboard-container"
      style={{
        // backgroundImage: `url(${dashboard})`,
        backgroundColor: "#eef0f5",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <button className="toggle-button" onClick={toggleSidebar}>
        ☰
      </button>
      <div
        className={`left-sidebar ${isSidebarVisible ? "visible" : "hidden"}`}
      >
        <h2>Dashboard</h2>
        <ul>
          <li
            className={`${active == "address" ? "" : "bg-light"}`}
            onClick={() => handleNavigation("address")}
          >
            <div className="nav-link-content">
              <FontAwesomeIcon icon={faHome} className="icon" />
              <span>Services's Address</span>
            </div>
          </li>
          <li
            className={`${active == "services" ? "" : "bg-light"}`}
            onClick={() => handleNavigation("services")}
          >
            <div className="nav-link-content">
              <FontAwesomeIcon icon={faBox} className="icon" />
              <span>Add New Services</span>
            </div>
          </li>
          <li
            className={`${active == "account" ? "" : "bg-light"}`}
            onClick={() => handleNavigation("account")}
          >
            <div className="nav-link-content">
              <FontAwesomeIcon icon={faUser} className="icon" />
              <span>Profile</span>
            </div>
          </li>
          <li
            className={`${active == "vendor_services" ? "" : "bg-light"}`}
            onClick={() => handleNavigation("vendor_services")}
          >
            <div className="nav-link-content">
              <FontAwesomeIcon icon={faCog} className="icon" />
              <span>My Service</span>
            </div>
          </li>
          <li
            className={`${active == "bookings" ? "" : "bg-light"}`}
            onClick={() => handleNavigation("bookings")}
          >
            <div className="nav-link-content">
              <FontAwesomeIcon icon={faCalendar} className="icon" />
              <span>Upcoming Bookings</span>
            </div>
          </li>
          <li
            className={`${active == "bookinghistory" ? "" : "bg-light"}`}
            onClick={() => handleNavigation("bookinghistory")}
          >
            <div className="nav-link-content">
              <FontAwesomeIcon icon={faClock} className="icon" />
              <span>Bookings History</span>
            </div>
          </li>
          <li onClick={handleLogout}>
            <div
              className="nav-link-content"
              style={{
                color: "#ff4d4d",
                fontWeight: "bold",
              }}
            >
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="icon"
                style={{
                  color: "#ff4d4d",
                }}
              />
              <span>Logout</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="right-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
