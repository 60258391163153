import React, { useEffect, useState } from "react";
import { APIEndtPoint } from "./Apis";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import "../css/CurrentVendorService.css";

const CurrentVendorServices = () => {
  const [vendorServices, setVendorServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [newPrice, setNewPrice] = useState("");

  useEffect(() => {
    const fetchVendorServices = async () => {
      try {
        const response = await fetch(
          APIEndtPoint + "v1/vendors/vendor_services",
          {
            method: "GET",
            headers: {
              Authorization: localStorage.getItem("userToken"),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch services");

        const data = await response.json();
        setVendorServices(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching vendor services:", err);
        // setError("No Service Found");
        setError(
          <span style={{ fontWeight: "bold", color: "darkred" }}>
            No Service Found
          </span>
        );
        setLoading(false);
      }
    };

    fetchVendorServices();
  }, []);

  const openModal = (type, service = null) => {
    setModalType(type);
    setCurrentService(service);
    if (type === "edit" && service) {
      setNewPrice(service.service.price);
    }
  };

  const closeModal = () => {
    setModalType(null);
    setCurrentService(null);
    setNewPrice("");
  };

  const saveEdit = async () => {
    if (!newPrice || isNaN(newPrice)) {
      alert("Please enter a valid price.");
      return;
    }

    try {
      const response = await fetch(
        `${APIEndtPoint}v1/vendors/${currentService.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: localStorage.getItem("userToken"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            vendor_service: {
              price: parseFloat(newPrice),
            },
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update price");

      setVendorServices((prevServices) =>
        prevServices.map((service) =>
          service.id === currentService.id
            ? { ...service, service: { ...service.service, price: newPrice } }
            : service
        )
      );
      closeModal();
    } catch (err) {
      console.error("Error updating service:", err);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(
        `${APIEndtPoint}v1/vendors/${currentService.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("userToken"),
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete service");

      setVendorServices((prevServices) =>
        prevServices.filter((service) => service.id !== currentService.id)
      );
      closeModal();
    } catch (err) {
      console.error("Error deleting service:", err);
    }
  };

  if (loading) return <div>Loading services...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="vendor-services-list">
        {vendorServices.map((serviceData) => {
          const { id, service, location } = serviceData;
          return (
            <div key={id} className="service-card">
              <div className="service-icons">
                <FaEdit
                  onClick={() => openModal("edit", serviceData)}
                  className="edit-icon"
                />
                <FaTrashAlt
                  onClick={() => openModal("delete", serviceData)}
                  className="delete-icon"
                />
              </div>
              <h2>{service.title}</h2>
              <p className="price">Price: ₹{service.price}</p>
              <p
                className="price"
                style={{
                  color:
                    serviceData.status === "approved"
                      ? "green"
                      : serviceData.status === "pending"
                      ? "#FCB603"
                      : serviceData.status === "rejected"
                      ? "red"
                      : "black",
                }}
              >
                Status: {serviceData.status}
              </p>
              <div className="service-location">
                <h3>Location:</h3>
                <p>{location.address1}</p>
                <p>
                  {location.locality}, {location.city}, {location.pin_code}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Edit Modal */}
      {modalType === "edit" && currentService && (
        <div
          className="modal d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content bg-white p-4 rounded shadow"
            style={{
              width: "400px",
              textAlign: "center",
              animation: "slideIn 0.3s ease",
            }}
          >
            <h3 className="mb-3">
              Edit price for {currentService.service.title}
            </h3>
            <input
              type="number"
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
              className="form-control mb-3"
              placeholder="Enter new price"
              style={{ width: "90%", margin: "0 auto" }}
            />
            <div className="d-flex justify-content-around">
              <button onClick={saveEdit} className="btn btn-primary">
                Save
              </button>
              <button onClick={closeModal} className="btn btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {modalType === "delete" && currentService && (
        <div
          className="modal d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content bg-white p-4 rounded shadow"
            style={{
              width: "400px",
              textAlign: "center",
              animation: "slideIn 0.3s ease",
            }}
          >
            <h3 className="mb-4">
              Are you sure you want to delete {currentService.service.title}?
            </h3>
            <div className="d-flex justify-content-around">
              <button onClick={confirmDelete} className="btn btn-danger">
                Delete
              </button>
              <button onClick={closeModal} className="btn btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentVendorServices;
