import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../Pages/Logo";
import logoImage from "../img/logo-preview.png";
function Header(data) {
  const [cartItemCount, setCartItemCount] = useState(0);

  const updateCartItemCount = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
    setCartItemCount(totalItems);
  };

  useEffect(() => {
    updateCartItemCount();
  }, []);

  useEffect(() => {
    updateCartItemCount();
  }, [data.cartItem?.length]);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="row bg-dark d-none d-lg-flex">
              <div className="col-lg-7 text-left text-white">
                <div className="h-100 d-inline-flex align-items-center border-right border-primary py-2 px-3">
                  <i className="fa fa-envelope text-primary mr-2"></i>
                  <small>
                    <Link
                      to="mailto:helpdesk@safaixyz.com"
                      className="text-white"
                    >
                      helpdesk@safaixyz.com
                    </Link>
                  </small>
                </div>
                <div className="h-100 d-inline-flex align-items-center py-2 px-2">
                  <i className="fa fa-phone-alt text-primary mr-2"></i>
                  <small>
                    <Link to="tel:+91-8006305606" className="text-white">
                      +91-8006305606
                    </Link>
                  </small>
                </div>
              </div>
              <div className="col-lg-5 text-right">
                <div className="d-inline-flex align-items-center pr-2">
                  <Link
                    className="text-primary p-2"
                    to="https://facebook.com/safaixyz"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                
                  <Link
                    className="text-primary p-2"
                    to="https://www.instagram.com/safaixyz?igsh=MWltajU4NTMxdDJvdw=="
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link
                    className="text-primary p-2"
                    to="https://www.youtube.com/@Safaixyz"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-3">
        <div className="">
          <div className="">
            <div className="row">
              <div className="col-lg-3 d-none d-lg-block"></div>
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <Link to="/" className="d-block d-lg-none" style={{ width: "50%" }}>
                    <img src={logoImage} alt="" className="img-fluid" style={{ width: "25%", float: "left" }}/>
                  </Link>
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbarCollapse"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="navbarCollapse"
                  >
                    <div className="navbar-nav mr-auto py-0">
                      <Link to="/" className="web-main-logo d-none d-lg-flex">
                        <Logo></Logo>
                      </Link>
                      <Link
                        to="/"
                        className={
                          data.data === "home"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Home
                      </Link>
                      <Link
                        to="/about"
                        className={
                          data.data === "about"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        About
                      </Link>
                      <Link
                        to="/service"
                        className={
                          data.data === "service"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Services
                      </Link>

                      <Link
                        to="/projects"
                        className={
                          data.data === "projects"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Projects
                      </Link>

                      <Link
                        to="/careers"
                        className={
                          data.data === "careers"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Careers
                      </Link>
                      <Link
                        to="/contact"
                        className={
                          data.data === "contact"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Contact
                      </Link>
                      <Link
                        to="/cartdetails"
                        className={
                          data.data === "cart"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Cart{cartItemCount > 0 && (
                          <sup><span className="cart-item-count">{cartItemCount}</span></sup>
                        )}
                      </Link>

                      {/* <Link
                        to="/registration"
                        className={
                          data.data === "registration"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                      >
                        Registration
                      </Link> */}

                    </div>
                    {/* <Link to="#" className="nav-item nav-link">
                  <img src={whatsAppImage} style={{width: "50px"}} alt="WhatsApp" />
                  +91-8006305606
                </Link> */}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;