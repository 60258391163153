import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";

function Bookings() {
  const [bookings, setBookings] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    async function fetchBookings() {
      try {
        const response = await fetch(
          `${APIEndtPoint}/bookings/vendor_upcoming_bookings`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("userToken"),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch bookings");
        }
        const data = await response.json();
        setBookings(data);
        setIsReload(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    }

    fetchBookings();
  }, [isReload]);

  async function updateBookingStatus(id, newStatus) {
    try {
      const response = await fetch(
        `${APIEndtPoint}/bookings/${id}/accept_reject_booking`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("userToken"),
          },
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (!response.ok) throw new Error("Failed to update booking status.");
      const data = await response.json();

      setBookings((prev) =>
        prev.map((booking) =>
          booking.id === id ? { ...booking, status: newStatus } : booking
        )
      );
      setIsReload(true);
    } catch (error) {
      console.error(error);
      alert("An error occurred while updating the booking status.");
    }
  }

  const filteredBookings = bookings.filter((booking) =>
    activeTab === "All"
      ? true
      : activeTab === "Upcoming"
      ? booking.booking_status === "Pending"
      : activeTab === "accepted"
      ? booking.booking_status === "accepted"
      : activeTab === "rejected"
      ? booking.booking_status === "rejected"
      : true
  );

  return (
    <div>
      <style>
        {`
          .bookings {
            text-align: left;
          }
          .tabs-booking {
            display: flex;
            gap: 10px;
            margin-bottom: 20px;
          }
          .tab-button {
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            border-radius: 4px;
          }
          .tab-button.active {
            background-color:#9fcced;
            color: black;
          }
          .bookings-list {
            display: flex;
            flex-direction: row;
            gap: 20px;
          }
          .booking-card {
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 16px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          .booking-details {
            margin-bottom: 12px;
          }
          .bookingid {
            font-size: 0.9rem;
          }
          .booking-actions {
            display: flex;
            gap: 10px;
          }
          .accept-button,
          .reject-button {
            padding: 8px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 32px;
            align-items: center;
            display: flex;
            justify-content: center;
          }
          .accept-button {
            background-color: #4caf50;
            color: white;
          }
          .reject-button {
            background-color: #f44336;
            color: white;
          }
          .accept-button:hover {
            background-color: #45a049;
          }
          .reject-button:hover {
            background-color: #e53935;
          }
          .accept-button.disabled,
          .reject-button.disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        `}
      </style>
      <h2 className="bookings">Bookings</h2>

      <div className="tabs-booking">
        <button
          className={`tab-button col-lg-3 border-bottom ${
            activeTab === "All" ? "active fw-bold" : ""
          }`}
          onClick={() => setActiveTab("All")}
        >
          All
        </button>
        <button
          className={`tab-button col-lg-3 border-bottom ${
            activeTab === "Upcoming" ? "active fw-bold" : ""
          }`}
          onClick={() => setActiveTab("Upcoming")}
        >
          Upcoming
        </button>
        <button
          className={`tab-button col-lg-3 border-bottom ${
            activeTab === "accepted" ? "active fw-bold" : ""
          }`}
          onClick={() => setActiveTab("accepted")}
        >
          Accepted
        </button>
        <button
          className={`tab-button col-lg-3 border-bottom ${
            activeTab === "rejected" ? "active fw-bold" : ""
          }`}
          onClick={() => setActiveTab("rejected")}
        >
          Rejected
        </button>
      </div>

      {filteredBookings.length > 0 ? (
        <div className="bookings-list">
          {filteredBookings.map((booking) => (
            <div className="booking-card col-lg-3" key={booking.id}>
              <div className="booking-details">
                <p>
                  <strong>Customer Name:</strong>{" "}
                  {`${booking.first_name} ${booking.last_name}`}
                </p>
                <p>
                  <strong> Service's:</strong>{" "}
                  {booking.products.map((product, index) => (
                    <li key={index}>{product.short_title}</li>
                  ))}
                </p>
                <p>
                  <strong> Phone number:</strong> {booking.phone_number}
                </p>
                <p>
                  <strong>Date:</strong> {booking.booking_date}
                </p>
                <p>
                  <strong>Status:</strong> {booking.booking_status}
                </p>
              </div>
              <div className="booking-actions">
                <button
                  className={`accept-button ${
                    booking.booking_status === "accepted" ? "disabled" : ""
                  }`}
                  onClick={() => updateBookingStatus(booking.id, "accepted")}
                  disabled={booking.booking_status === "accepted"}
                >
                  Accept
                </button>
                <button
                  className={`reject-button ${
                    booking.booking_status === "rejected" ? "disabled" : ""
                  }`}
                  onClick={() => updateBookingStatus(booking.id, "rejected")}
                  disabled={booking.booking_status === "rejected"}
                >
                  Reject
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No bookings available.</p>
      )}
    </div>
  );
}

export default Bookings;
