// import logo from './logo.svg';
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import "./Components/lib/owlcarousel/assets/owl.carousel.min.css";
import "./Components/lib/lightbox/css/lightbox.min.css";
import "./Components/css/style.css";
import "./Components/css/plans.css";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Service from "./Components/Pages/Service";
import Projects from "./Components/Pages/Projects";
import BuyNow from "./Components/Pages/BuyNow";
import Blog from "./Components/Pages/Blog";
import Contact from "./Components/Pages/Contact";
import Checkout from "./Components/Pages/Checkout";
import CopyRight from "./Components/Pages/CopyRight";
import Single from "./Components/Pages/Single";
import SignUp from "./Components/Pages/SignUp";
import SignIn from "./Components/Pages/SignIn";
import BasicDetails from "./Components/Pages/BasicDetails";
import Booked from "./Components/Pages/Booked";
import SubCategory from "./Components/Pages/SubCategory";
import Footer from "./Components/Pages/Footer";
import WashRoomSubCategory from "./Components/Pages/WashRoomSubCategory";
import KitchenSubCategory from "./Components/Pages/KitchenSubCategory";
import HouseCleaningSubCategory from "./Components/Pages/HouseCleaningSubCategory";
import CartDetailsForMobile from "./Components/Pages/CartDetailsForMobile";
import Carees from "./Components/Pages/Carees";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import VendorForm from "./Components/Pages/VendorForm";
import Registration from "./Components/Pages/Registration";
import Dashboard from "./Components/Pages/Dashboard";
import AccountComponent from "./Components/Pages/AccountComponent";
import ServiceComponent from "./Components/Pages/ServiceComponent";
import CurrentVendorServices from "./Components/Pages/CurrentVendorServices";
import AddressComponent from "./Components/Pages/AddressComponent";
import AddNewService from "./Components/Pages/AddNewService";
import Bookings from "./Components/Pages/Bookings";
import BookingHistory from "./Components/Pages/BookingHistory";

function loadScript(src, position = document.head) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = false;
    script.onload = resolve;
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    position.appendChild(script);
  });
}

function useLoadScripts() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const styleLinks = [
      "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap",
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css",
    ];
    const preconnects = ["https://fonts.gstatic.com"];

    const basePublicPath = process.env.PUBLIC_URL;
    const scriptsInOrder = [
      "https://code.jquery.com/jquery-3.4.1.min.js",
      "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.bundle.min.js",
      `${basePublicPath}/lib/owlcarousel/owl.carousel.min.js`,
      `${basePublicPath}/lib/easing/easing.min.js`,
      `${basePublicPath}/lib/counterup/counterup.min.js`,
      `${basePublicPath}/lib/lightbox/js/lightbox.min.js`,
      `${basePublicPath}/lib/waypoints/waypoints.min.js`,
      `${basePublicPath}/lib/isotope/isotope.pkgd.min.js`,
      `${basePublicPath}/js/main.js`,
    ];

    const loadScripts = () => {
      scriptsInOrder
        .reduce(
          (promise, script) => promise.then(() => loadScript(script)),
          Promise.resolve()
        )
        .then(() => console.log("All scripts loaded successfully"))
        .catch((error) => console.error("Script loading error:", error));

      preconnects.forEach((href) => {
        const link = document.createElement("link");
        link.rel = "preconnect";
        link.href = href;
        link.crossOrigin = "";
        document.head.appendChild(link);
      });

      styleLinks.forEach((href) => {
        const link = document.createElement("link");
        link.href = href;
        link.rel = "stylesheet";
        document.head.appendChild(link);
      });
    };
    loadScripts();

    return () => {
      [...styleLinks, ...preconnects].forEach((href) => {
        const link = document.head.querySelector(`link[href="${href}"]`);
        if (link) {
          document.head.removeChild(link);
        }
      });
      scriptsInOrder.forEach((src) => {
        let script = document.head.querySelector(`script[src="${src}"]`);
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, [location.pathname]);
}

function useScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

function Layout({ children }) {
  useLoadScripts();
  useScrollToTop();
  return <>{children}</>;
}

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <About />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/service"
            element={
              <>
                <Service />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/projects"
            element={
              <>
                <Projects />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/blog"
            element={
              <>
                <Blog />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/careers"
            element={
              <>
                <Carees />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/buynow"
            element={
              <>
                <BuyNow />
                <Footer />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Contact />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/checkout"
            element={
              <>
                <Checkout />
              </>
            }
          />
          <Route
            path="/single"
            element={
              <>
                <Single />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <SignUp />
              </>
            }
          />
          <Route
            path="/signin"
            element={
              <>
                <SignIn />
              </>
            }
          />
          <Route
            path="/basic-details"
            element={
              <>
                <BasicDetails />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/booked"
            element={
              <>
                <Booked />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/sub-category"
            element={
              <>
                <SubCategory />
                <Footer />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/washroom-subcategory"
            element={
              <>
                <WashRoomSubCategory />
              </>
            }
          />
          <Route
            path="/kitchen-sub-category"
            element={
              <>
                <KitchenSubCategory />
              </>
            }
          />
          <Route
            path="/house-cleanning-sub-category"
            element={
              <>
                <HouseCleaningSubCategory />
              </>
            }
          />
          <Route
            path="/cartdetails"
            element={
              <>
                <CartDetailsForMobile />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <PrivacyPolicy />
                <CopyRight />
              </>
            }
          />
          <Route
            path="/addVendor"
            element={
              <>
                <VendorForm />
                <CopyRight />
              </>
            }
          />
          {/* <Route path='/registration' element={<><Registration /><CopyRight/></>} />
          <Route path='/dashboard' element={<><Dashboard /><CopyRight/></>} />
          <Route path="/services" element={<ServiceComponent />} />
          <Route path="/account" element={<AccountComponent />} />
          <Route path='/vendor_services' element={<CurrentVendorServices />} /> */}
          <Route path="/registration" element={<Registration />} />
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<ServiceComponent />} />
            <Route path="address" element={<AddressComponent />} />
            <Route path="services" element={<ServiceComponent />} />
            <Route path="account" element={<AccountComponent />} />
            <Route path="vendor_services" element={<CurrentVendorServices />} />
            <Route path="addNewService" element={<AddNewService />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="bookinghistory" element={<BookingHistory />} />
          </Route>
          {/* <Route path="/registration" element={<Registration />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
