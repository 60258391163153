import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";
import "../css/AddressComponent.css";

const AddressComponent = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [addressDetails, setAddressDetails] = useState({
    address: "",
    pinCode: "",
    locality: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [savedAddress, setSavedAddress] = useState(null);
  const token = localStorage.getItem("userToken");
  const fetchCities = async () => {
    try {
      const response = await fetch(APIEndtPoint + "v1/get_locations", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setCities(data.locations);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchAddressData = async () => {
    try {
      const response = await fetch(APIEndtPoint + "v1/addresses", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 404) {
        console.log("No address found. Showing create form.");
        setSavedAddress(null);
        setIsEditing(true);
        return;
      }

      const data = await response.json();
      const address = data[0];
      setSavedAddress(address);
      setAddressDetails({
        address: address.address1,
        pinCode: address.pin_code,
        locality: address.locality,
      });
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    fetchCities();
    fetchAddressData();
  }, []);

  useEffect(() => {
    if (savedAddress) {
      setAddressDetails({
        address: savedAddress.address1 || "",
        pinCode: savedAddress.pin_code || "",
        locality: savedAddress.locality || "",
      });
      setSelectedCity(savedAddress.city || "");
    }
  }, [savedAddress]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressDetails({ ...addressDetails, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const payload = {
      vendor_address: {
        address1: addressDetails.address,
        pin_code: addressDetails.pinCode,
        city: selectedCity,
        state: "UP",
        locality: addressDetails.locality,
        address_id: selectedCity,
      },
    };

    try {
      let response;
      if (savedAddress) {
        response = await fetch(
          `${APIEndtPoint}v1/addresses/${savedAddress.id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        console.log("nmnm", response);
      } else {
        response = await fetch(`${APIEndtPoint}v1/addresses`, {
          method: "POST",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      }

      const result = await response.json();
      if (response.ok) {
        alert(
          savedAddress
            ? "Address updated successfully!"
            : "Address created successfully!"
        );
        setSavedAddress(result.vendor_address);
        setIsEditing(false);
      } else {
        alert(
          savedAddress
            ? "Failed to update address."
            : "Failed to create address."
        );
      }
    } catch (error) {
      console.error(
        savedAddress ? "Error updating address:" : "Error creating address:",
        error
      );
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(
        APIEndtPoint + `v1/addresses/${savedAddress.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      await response.json();
      setSavedAddress(null);
      alert("Address deleted successfully!");
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  return (
    <div className="main-container">
      {isEditing ? (
        <div
          className="row p-3 border rounded bg-light"
          style={{
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
          }}
        >
          <h4 className="col-12">
            {savedAddress
              ? "Edit Service's Address"
              : "Create Service's Address"}
          </h4>

          <form onSubmit={handleSave} className="edit-form row col-lg-12">
            <div className="form-group col-md-6 col-sm-12 mb-3">
              <label htmlFor="city">Select City</label>
              <select
                id="city"
                name="city"
                className="form-control"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                required
              >
                <option value="">Select City</option>
                {cities.length > 0 ? (
                  cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No Cities Available</option>
                )}
              </select>
            </div>

            <div className="form-group col-md-6 col-sm-12 mb-3">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                className="form-control"
                placeholder="Enter your address"
                value={addressDetails.address}
                onChange={handleAddressChange}
                required
              />
            </div>

            <div className="form-group col-md-6 col-sm-12 mb-3">
              <label htmlFor="pinCode">Pin Code</label>
              <input
                type="text"
                id="pinCode"
                name="pinCode"
                className="form-control"
                placeholder="Enter pin code"
                value={addressDetails.pinCode}
                onChange={handleAddressChange}
                required
              />
            </div>

            <div className="form-group col-md-6 col-sm-12 mb-3">
              <label htmlFor="locality">Locality</label>
              <input
                type="text"
                id="locality"
                name="locality"
                className="form-control"
                placeholder="Enter locality"
                value={addressDetails.locality}
                onChange={handleAddressChange}
                required
              />
            </div>

            <div className="col-12 d-flex justify-content-between mt-3">
              <button type="submit" className="btn btn-primary m-2">
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary cancel-btn m-2"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        savedAddress && (
          <div className="saved-address p-3 border rounded bg-light">
            <h4>Service's Address</h4>
            <p>
              <strong>City:</strong> {savedAddress.city}
            </p>
            <p>
              <strong>Address:</strong> {savedAddress.address1}
            </p>
            <p>
              <strong>Pin Code:</strong> {savedAddress.pin_code}
            </p>
            <p>
              <strong>Locality:</strong> {savedAddress.locality}
            </p>

            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-primary" onClick={handleEditClick}>
                Edit
              </button>
              <button
                className="btn btn-danger delete-btn"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AddressComponent;
