import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";
import "../css/ServiceComponent.css";
import { Link } from "react-router-dom";

const ServiceComponent = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [activeServiceId, setActiveServiceId] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [servicePrices, setServicePrices] = useState({});
  const [servicesOffered, setServicesOffered] = useState([]);
  const [vendorAddresses, setVendorAddresses] = useState([]);
  const token = localStorage.getItem("userToken");
  const [error, setError] = useState(null);
  const [isVendorAddressAvailable, setIsVendorAddressAvailable] = useState(false);
  const [isProfilesAvailable, setIsProfilesAvailable] = useState(false);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(APIEndtPoint + "show_categories_product");
        if (!response.ok) throw new Error("Failed to fetch services.");
        const data = await response.json();
        setServicesOffered(data || []);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const fetchVendorAddresses = async () => {
      try {
        const response = await fetch(APIEndtPoint + "v1/addresses", {
          method: "GET",
          headers: {
            Authorization: token,
          },
        });
        if (!response.ok) {
          setIsVendorAddressAvailable(true);
          return;
        }
        const data = await response.json();
        setVendorAddresses(data || []);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchVendorAddresses();
  }, [token]);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("User token not found. Please log in again.");
        }

        const response = await fetch(APIEndtPoint + "v1/profiles/get_profile", {
          headers: {
            Authorization: token,
          },
        });
        if (!response.ok) {
          throw new Error("Profile not found. Please complete your profile.");
        }

        const data = await response.json();
        if (data.data.attributes.document_url) {
          setIsProfilesAvailable(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchAccountDetails();
  }, []);

  const handleServiceSelection = (serviceId) => {
    setSelectedServices((prev) =>
      prev.includes(serviceId)
        ? prev.filter((id) => id !== serviceId)
        : [...prev, serviceId]
    );
    setActiveServiceId(serviceId === activeServiceId ? null : serviceId);
  };

  const handleProductSelection = (serviceId, productId) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        [productId]: !prev[serviceId]?.[productId],
      },
    }));
  };

  const handlePriceChange = (serviceId, productId, price) => {
    setServicePrices((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        [productId]: parseFloat(price) || 0,
      },
    }));
  };

  const handleServiceSubmit = async () => {
    if (selectedServices.length === 0) {
      alert("Please select at least one service before submitting.");
      return;
    }

    if (vendorAddresses.length === 0) {
      alert("No vendor addresses available. Please add one before submitting.");
      return;
    }

    const payload = {
      products: selectedServices.flatMap((serviceId) => {
        const service = servicesOffered.find((s) => s.id === serviceId);
        return service.products
          .filter((product) => selectedProducts[serviceId]?.[product.id])
          .map((product) => ({
            product_id: product.id,
            price: parseFloat(servicePrices[serviceId]?.[product.id]) || 0,
          }));
      }),
      vendor_address_ids: vendorAddresses.map((address) => address.id),
    };

    try {
      const response = await fetch(APIEndtPoint + "v1/vendors", {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Services submitted successfully!");
        window.location.reload();
        setSelectedServices([]);
        setServicePrices({});
        setSelectedProducts({});
      } else {
        const error = await response.json();
        alert(`Error: ${error.message || "Submission failed."}`);
      }
    } catch (err) {
      console.error("Network error:", err);
      alert("There was a network error. Please try again later.");
    }
  };

  return (
    <div className="services-section px-1">
      {isVendorAddressAvailable ? (
        <>
          <h5>
            {!isProfilesAvailable ? (
              <>
                Please complete your profile first <Link to="/dashboard/account">Click here</Link>
              </>
            ) : (
              <>
                Please add a service address first <Link to="/dashboard/address">Click here</Link>
              </>
            )}
          </h5>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Select Services</h3>
            <Link to="/dashboard/addNewService" className="btn btn-primary add-service-btn">
              Add New Service
            </Link>
          </div>

          {error && <p className="error">{error}</p>}

          <div className="row">
            {servicesOffered.map((service) => (
              <div className="col-lg-3 col-md-6">
                <div key={service.id} className="services-offered">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedServices.includes(service.id)}
                      onChange={() => handleServiceSelection(service.id)}
                    />
                    {service.name}
                  </label>
                  {activeServiceId === service.id && (
                    <div className="bg-light p-3 rounded w-100">
                      <h6>Products for {service.name}</h6>
                      <ul>
                        {service.products.map((product) => (
                          <li key={product.id}>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectedProducts[service.id]?.[product.id] || false}
                                onChange={() => handleProductSelection(service.id, product.id)}
                              />
                              {product.title}
                            </label>
                            <input
                              type="number"
                              placeholder="price"
                              required
                              value={servicePrices[service.id]?.[product.id] || ""}
                              onChange={(e) =>
                                handlePriceChange(service.id, product.id, e.target.value)
                              }
                              className="price-input w-50"
                              disabled={!selectedProducts[service.id]?.[product.id]}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button className="centered-submit-btn" onClick={handleServiceSubmit}>
            Submit Services
          </button>
        </>
      )}
    </div>
  );
};

export default ServiceComponent;
